import { createRouter, createWebHashHistory } from 'vue-router'
import { jwtVerify } from 'jose'
import app from '@/main'
import VueJwtDecode from 'vue-jwt-decode'

import HomeView from '@/views/HomeView'
import LoginView from '@/views/LoginView'
import AdminView from '@/views/AdminView'
import VehiclesView from '@/views/admin/VehiclesView'
import AracKayitView from '@/views/admin/AracKayitView'
import DriverAddView from '@/views/admin/DriverAddView'
import DriverAssingView from '@/views/admin/DriverAssingView'
import DriverView from '@/views/admin/DriverView'
import DriverAndCar from '@/views/admin/VehiclesAndDrivers'
import ShubyView from '@/views/admin/ShubyView'
import TaxesView from '@/views/admin/TaxesView'
import InsuranceView from '@/views/admin/InsuranceView'
import KaskoView from '@/views/admin/KaskoView'
import MaintenanceView from '@/views/admin/MaintenanceView'
import WheelView from '@/views/admin/WheelView'
import FuelView from '@/views/admin/FuelView'
import UserView from '@/views/admin/userView'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { requiresAuth: false }
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: { requiresAuth: false }
  },
  {
    path: '/admin',
    name: 'admin',
    component: AdminView,
    meta: { requiresAuth: true }
  },
  {
    path: '/vehicles',
    name: 'vehicles',
    component: VehiclesView,
    meta: { requiresAuth: true }
  },
  {
    path: '/createVehicle',
    name: 'createVehicle',
    component: AracKayitView,
    meta: { requiresAuth: true }
  },
  {
    path: '/driverCreate',
    name: 'driverCreate',
    component: DriverAddView,
    meta: { requiresAuth: true }
  },
  {
    path: '/driverAssing',
    name: 'driverAssing',
    component: DriverAssingView,
    meta: { requiresAuth: true }
  },
  {
    path: '/driver',
    name: 'driver',
    component: DriverView,
    meta: { requiresAuth: true }
  },
  {
    path: '/driverAndCar',
    name: 'driverAndCar',
    component: DriverAndCar,
    meta: { requiresAuth: true }
  },
  {
    path: '/shuby',
    name: 'shuby',
    component: ShubyView,
    meta: { requiresAuth: true }
  },
  {
    path: '/taxes',
    name: 'taxes',
    component: TaxesView,
    meta: { requiresAuth: true }
  },
  {
    path: '/insurance',
    name: 'insurance',
    component: InsuranceView,
    meta: { requiresAuth: true }
  },
  {
    path: '/comprehensiveInsurance',
    name: 'comprehensiveInsurance',
    component: KaskoView,
    meta: { requiresAuth: true }
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    component: MaintenanceView,
    meta: { requiresAuth: true }
  },
  {
    path: '/wheel',
    name: 'wheel',
    component: WheelView,
    meta: { requiresAuth: true }
  },
  {
    path: '/fuel',
    name: 'fuel',
    component: FuelView,
    meta: { requiresAuth: true }
  },
  {
    path: '/user',
    name: 'user',
    component: UserView,
    meta: { requiresAuth: true }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const isAuthenticated = verifyToken()

  if (requiresAuth && !isAuthenticated) {
    next('/login')
  } else if (requiresAuth && isAuthenticated) {
    next()
  } else if (!requiresAuth && isAuthenticated) {
    next('/admin')
  } else {
    next()
  }
})
function verifyToken () {
  try {
    const token = app.config.globalProperties.$cookies.get('authToken')
    if (!token) {
      return false
    } else {
      const currentTimestamp = Math.floor(Date.now() / 1000)
      if (currentTimestamp > VueJwtDecode.decode(app.config.globalProperties.$cookies.get('authToken')).exp) {
        app.config.globalProperties.$cookies.remove('authToken')
        return false
      } else {
        const secret = new TextEncoder().encode(process.env.VUE_APP_SECRET_KEY)
        jwtVerify(token, secret, {
          issuer: process.env.VUE_APP_ISS_URL,
          audience: process.env.VUE_APP_API_URL
        })
        return true
      }
    }
  } catch (error) {    
    console.log(error.message)
    return false
  }
}
export default router
