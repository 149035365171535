<template>
    <div>
        <SideBar />
        <NavBar />
        <div class="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
            data-sidebar-position="fixed" data-header-position="fixed">
            <div class="container dataTableContainer">
                <DataTable :data="vehicles" :columns="columns" class="table table-striped table-bordered display"
                    id="vehicleDatatable" :options="dataTableOptionsWithButtons">
                    <thead>
                        <tr>
                            <th>id</th>
                            <th>{{ this.$t('vehicleView.plate') }}</th>
                            <th>{{ this.$t('vehicleView.chassisNumber') }}</th>
                            <th>{{ this.$t('vehicleView.deviceID') }}</th>
                            <th>{{ this.$t('vehicleView.brand') }}</th>
                            <th>{{ this.$t('vehicleView.edit') }}</th>
                            <th>{{ this.$t('vehicleView.delete') }}</th>
                        </tr>
                    </thead>
                </DataTable>
            </div>
            <!-- Modal -->
            <div class="modal fade" id="vehicleModel" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">{{ this.$t('vehicleView.heading') }}</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <form v-if="editedVehicle">
                                <div class="mb-3">
                                    <label for="chassisNumber" class="form-label">{{ this.$t('vehicleView.chassisNumber')
                                    }}</label>
                                    <input type="text" class="form-control" id="chassisNumber"
                                        v-model="editedVehicle.chassisNumber" required="" @input="onInput" maxlength="17">
                                </div>
                                <div class="mb-3">
                                    <label for="plaka" class="form-label">{{ $t('vehicleView.plate') }}</label>
                                    <input type="text" class="form-control" id="plaka" v-model="editedVehicle.plaka"
                                        required="" @input="onInput" maxlength="50">
                                </div>
                                <div class="mb-3">
                                    <label for="deviceID" class="form-label">{{ $t('vehicleView.deviceID') }}</label>
                                    <input type="text" class="form-control" id="deviceID" v-model="editedVehicle.deviceID"
                                        required="" @input="onInput" maxlength="10">
                                </div>
                                <div class="mb-3">
                                    <label for="marka" class="form-label">{{ $t('vehicleView.brand') }}</label>
                                    <input type="text" class="form-control" id="marka" v-model="editedVehicle.marka"
                                        required="" @input="onInput" maxlength="50">
                                </div>
                                <div class="mb-3">
                                    <label for="model" class="form-label">{{ $t('vehicleView.model') }}</label>
                                    <input type="text" class="form-control" id="model" v-model="editedVehicle.model"
                                        @input="onInput" maxlength="50">
                                </div>
                                <div class="mb-3">
                                    <label for="muayeneZamani" class="form-label">{{ $t('vehicleView.inspectionTime')
                                    }}</label>
                                    <input type="datetime-local" class="form-control" id="muayeneZamani"
                                        v-model="editedVehicle.muayeneZamani">
                                </div>
                                <div class="mb-3">
                                    <label for="kullanilanDepartman" class="form-label">{{ $t('vehicleView.department')
                                    }}</label>
                                    <input type="text" class="form-control" id="kullanilanDepartman"
                                        v-model="editedVehicle.kullanilanDepartman" @input="onInput" maxlength="100">
                                </div>
                                <div class="mb-3">
                                    <label for="yedekAnahtar" class="form-label">{{ $t('vehicleView.spareKey') }}</label>
                                    <input type="text" class="form-control" id="yedekAnahtar"
                                        v-model="editedVehicle.yedekAnahtar" @input="onInput" maxlength="100">
                                </div>
                            </form>
                        </div>
                        <a v-if="isSuccess" id="successText">{{ $t('vehicleView.success') }}</a>
                        <a id="errorText">{{ this.errorText }}</a>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{
                                $t('vehicleView.close') }}</button>
                            <button type="button" @click="saveChanges" class="btn btn-primary">{{ $t('vehicleView.save')
                            }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script scoped>
import SideBar from '@/components/admin/Sidebar'
import NavBar from '@/components/admin/Navbar'
import VueJwtDecode from 'vue-jwt-decode'
import axios from '@/server/axios'
import app from '@/main'


import DataTable from 'datatables.net-vue3'
import DataTableLib from 'datatables.net-bs5'
import Buttons from 'datatables.net-buttons-bs5'
import ButtonsHtml5 from 'datatables.net-buttons/js/buttons.html5'
import DataTableLength from 'datatables.net-bs5/js/dataTables.bootstrap5'
import exportToExcelMixin from '@/components/admin/js/exportToExcel'
import langChange from '@/components/admin/js/langChange'

DataTable.use(DataTableLength)
DataTable.use(DataTableLib)
DataTable.use(ButtonsHtml5)
DataTable.use(Buttons)

export default {
    mixins: [exportToExcelMixin, langChange],
    data() {
        return {
            vehicles: [],
            isSuccess: false,
            usersName: '',
            selectedVehicle: null,
            editedVehicle: {},
            successText: '',
            errorText: '',
            selectedYear: null,
            selectedPeriod: null,
            selectedPaymentDate: null,
            userID: {
                id: VueJwtDecode.decode(app.config.globalProperties.$cookies.get('authToken')).userID
            },
            columns: [
                { data: (data, type, row, meta) => meta.row + 1 },
                { data: 'plaka' },
                { data: 'chassisNumber' },
                { data: 'deviceID' },
                { data: 'marka' },
                {
                    data: null,
                    render: (data, type, row) => {
                        return '<button class="ti ti-settings edit-button" data-id="' + row.id + '" ></button>';
                    }
                },
                {
                    data: null,
                    render: (data, type, row) => {
                        return '<button class="ti ti-trash delete-button" data-id="' + row.id + '"></button>';
                    }
                },
            ],
            dataTableOptionsWithButtons: {
                responsive: true,
                autoWidth: false,
                lengthMenu: [10, 25, 50, 100], //
                dom: 'B<"row"<"col-md-6"l><"col-md-6"f>>rtip',
                buttons: [{
                    text: this.$t('vehicleView.convertExcel'), // Buton metni
                    action: this.exportExcel // Excel'e aktarma işlevi
                },
                ],
                language: {
                    url: this.lang()
                },
            }
        }
    },
    components: {
        SideBar,
        NavBar,
        DataTable,
    },
    mounted() {
        this.getVehicles()
        // burada datatable edit ve delete butonlarının click olaylarını  dinleyen bir event dinleyicisi oluşturuyoruz.
        const self = this;
        $('#vehicleDatatable').on('click', '.edit-button', function () {

            const vehicleId = $(this).data('id');
            const vehicle = self.vehicles.find(vehicle => vehicle.id === vehicleId);
            if (vehicle) {
                self.selectedVehicle = vehicle;
                self.editedVehicle = { ...vehicle };
                $('#vehicleModel').modal('show');
            }
        });
        $('#vehicleDatatable').on('click', '.delete-button', function () {
            const vehicleId = $(this).data('id');
            self.deleteVehicle(vehicleId);
        });
        $('#vehicleModel').on('hidden.bs.modal', () => {
            this.errorText = null
            this.isSuccess = false

        });
    },
    methods: {
        getVehicles() {
            this.userID.hash = window.location.hash.replace('#/', '')
            axios.post('getVehicle', this.userID)
                .then(response => {
                    this.vehicles = response.data.data;
                })
                .catch(error => {
                    console.log(error.message);
                });
        },
        saveChanges() {
            axios.post('update-vehicle', this.editedVehicle)
                .then(
                    response => {
                        if (response.status === 200) {
                            this.errorText = null
                            this.isSuccess = true
                            const index = this.vehicles.findIndex(vehicle => vehicle.id === this.editedVehicle.id)
                            this.vehicles[index] = this.editedVehicle
                        }
                        if (response.status === 204) {
                            this.errorText = this.$t('uptade.error')
                            this.isSuccess = false
                        }
                        if (response.status === 203) {
                            this.errorText = response.data.message
                            this.isSuccess = false
                        }
                    }
                )
                .catch(error => {
                    this.errorText = error.message
                })

        },
        async deleteVehicle(vehicleId) {
            try {
                const response = await axios.delete(`delete-vehicle/${vehicleId}`)

                if (response.status === 200) {
                    this.vehicles = this.vehicles.filter(vehicle => vehicle.id !== vehicleId)
                }
                if (response.status === 203) {
                    alert(this.$t('delete.error'))
                }
            } catch (error) {
                alert(error.message)
            }
        },
        exportExcel() {
            const columnName = ['id', this.$t('vehicleView.chassisNumber'), this.$t('vehicleView.deviceID'), this.$t('vehicleView.department'), this.$t('vehicleView.brand'),
                this.$t('vehicleView.model'), this.$t('vehicleView.inspectionTime'), this.$t('vehicleView.plate'), this.$t('vehicleView.spareKey')]
            this.exportToExcel(this.vehicles, "vehicles", columnName)
        },
        onInput() {
            if (this.editedVehicle.plaka) {
                this.editedVehicle.plaka = this.editedVehicle.plaka.toUpperCase()
            }
            if (this.editedVehicle.marka) {
                this.editedVehicle.marka = this.editedVehicle.marka.toUpperCase()
            }
            if (this.editedVehicle.model) {
                this.editedVehicle.model = this.editedVehicle.model.toUpperCase()
            }
            if (this.editedVehicle.kullanilanDepartman) {
                this.editedVehicle.kullanilanDepartman = this.editedVehicle.kullanilanDepartman.toUpperCase()
            }
            if (this.editedVehicle.yedekAnahtar) {
                this.editedVehicle.yedekAnahtar = this.editedVehicle.yedekAnahtar.toUpperCase()
            }
            if (this.editedVehicle.chassisNumber) {
                this.editedVehicle.chassisNumber = this.editedVehicle.chassisNumber.toUpperCase()
                this.editedVehicle.chassisNumber = this.editedVehicle.chassisNumber.replace(/[^A-HJ-NPR-Za-hj-npr-z0-9]/g, '')
            }
            if (this.editedVehicle.deviceID) {
                this.editedVehicle.deviceID = this.editedVehicle.deviceID.replace(/\D/g, '')
            }
        },
        watchLang(newLocale, oldLocale) {
            this.dataTableOptionsWithButtons.buttons[0].text = this.$t('vehicleView.convertExcel')
        }
    },
    watch: {
        '$i18n.locale': 'watchLang'
    },
}
</script>
<style scoped>
#vehiclesTable {
    width: 100%;
}

.modal-body {
    max-height: 60vh;
    overflow-y: auto;
}

.container {
    min-height: 650px;
}

#successText {
    margin-left: 30px;
    color: green;
}

#errorText {
    margin-left: 30px;
    color: red;
}
</style>