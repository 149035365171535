<template>
    <div class="taxesform">
        <SideBar />
        <NavBar />
        <div class="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
            data-sidebar-position="fixed" data-header-position="fixed">
            <div class="wrapper">
                <div class="container" style="margin-top:5%">
                    <form>
                        <div class="form-group">
                            <h2>{{ $t('user.heading') }}</h2>
                            <div class="row">
                                <div class="col-md-6">
                                    <label for="name">{{ $t('user.name') }}</label>
                                    <input class="form-control" v-model="user.name" readonly>
                                </div>
                                <div class="col-md-6">
                                    <label for="lastname">{{ $t('user.lastName') }}</label>
                                    <input class="form-control" v-model="user.lastname" readonly>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <label for="number">{{ $t('user.number') }}</label>
                                    <input class="form-control" v-model="user.number" readonly>
                                </div>
                                <div class="col-md-6">
                                    <label for="phonenumber">{{ $t('user.phoneNumber') }}</label>
                                    <input class="form-control" v-model="user.phonenumber" readonly>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <label for="company">{{ $t('user.company') }}</label>
                                    <input class="form-control" v-model="user.company" readonly>
                                </div>
                                <div class="col-md-6">
                                    <label for="tax">{{ $t('user.tax') }}</label>
                                    <input class="form-control" v-model="user.tax" readonly>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <label for="taxnumber">{{ $t('user.taxNumber') }}</label>
                                    <input class="form-control" v-model="user.taxnumber" readonly>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <label for="address">{{ $t('user.address') }}</label>
                                    <textarea style="resize:none" class="form-control" v-model="user.address" readonly></textarea>
                                </div>                                
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <label for="postcode">{{ $t('user.postcode') }}</label>
                                    <input class="form-control" v-model="user.postcode" readonly>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <label for="country">{{ $t('user.country') }}</label>
                                    <input class="form-control" v-model="user.country" readonly>
                                </div>
                                <div class="col-md-4">
                                    <label for="state">{{ $t('user.state') }}</label>
                                    <input class="form-control" v-model="user.state" readonly>
                                </div>
                                <div class="col-md-4">
                                    <label for="city">{{ $t('user.city') }}</label>
                                    <input class="form-control" v-model="user.city" readonly>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import SideBar from '@/components/admin/Sidebar.vue'
import NavBar from '@/components/admin/Navbar.vue'
import axios from '@/server/axios'
import VueJwtDecode from 'vue-jwt-decode'
import app from '@/main'

export default {
    components: {
        SideBar,
        NavBar,
    },
    data() {
        return {
            userID: {
                id: VueJwtDecode.decode(app.config.globalProperties.$cookies.get('authToken')).userID,
                hash: window.location.hash.replace('#/', '')
            },
            user: [],

        };
    },
    computed: {
        getStyle() {
            return {
                color: this.isSuccess ? 'green' : 'red'
            }
        }
    },
    mounted() {
        this.userInformation()
    },
    methods: {
        async userInformation() {
            await axios.post('user-information')
                .then(response => {
                    this.user = response.data.data
                })
                .catch(error => {
                    this.formText = error.message
                });
        } 
    }
}
</script>
  
<style scoped>
input[type=text],
select {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}
</style>
   