<template>
  <div>
    <SideBar />
    <NavBar />
    <div class="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
      data-sidebar-position="fixed" data-header-position="fixed">
      <div class="wrapper">
        <div class="container">
          <form @submit.prevent="vehicleCreate">
            <h2>{{ $t('createVehicle.heading') }}</h2>
            <label>{{ $t('createVehicle.plate') }}</label>
            <input type="text" class="form-control" v-model="vehicle.plaka" name="plaka" required="" autofocus=""
              @input="onInput">

            <label>{{ $t('createVehicle.chassisNumber') }}</label>
            <input type="text" class="form-control" v-model="vehicle.chassisNumber" name="chassisNumber" required=""
              @input="onInput" maxlength="17">

            <label>{{ $t('createVehicle.deviceID') }}</label>
            <input type="text" class="form-control" v-model="vehicle.deviceID" name="deviceID" required=""
              @input="onInput" maxlength="10">
            <br>
            <button class="btn btn-lg btn-primary btn-block" type="submit">{{ $t('createVehicle.submit') }}</button>
            <br><br>
            <div class="form-group">
              <a v-if="isSuccess" id="successText">{{ $t('createDriver.success') }}</a>
              <a id="errorText">{{ this.errorText }}</a>
            </div>
          </form>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import SideBar from '@/components/admin/Sidebar.vue'
import NavBar from '@/components/admin/Navbar.vue'
import VueJwtDecode from 'vue-jwt-decode'
import axios from '@/server/axios'
import app from '@/main'
export default {
  components: {
    SideBar,
    NavBar,
  },
  data() {
    return {
      vehicle: {
        chassisNumber: '',
        deviceID: '',
        plaka: '',
        userID: ''        
      },
      isSuccess: false,
      successText: '',
      errorText: '',
      userID: VueJwtDecode.decode(app.config.globalProperties.$cookies.get('authToken')).userID
    }
  },
  methods: {
    vehicleCreate() {
      this.vehicle.userID = this.userID
      axios.post('createVehicle', this.vehicle)
        .then(response => {
              if (response.status === 200) {
                this.errorText = ''
                this.isSuccess = true
                this.vehicle.chassisNumber = ''
                this.vehicle.deviceID = ''
                this.vehicle.plaka = ''
              } if (response.status === 203) {
                this.isSuccess = false
                this.errorText = response.data.message
              }
            
          }
        )
        .catch(error => {
          this.errorText = error.message
        });
    },
    onInput() {
      if (this.vehicle.plaka) {
        this.vehicle.plaka = this.vehicle.plaka.toUpperCase()
      }

      if (this.vehicle.chassisNumber) {
        this.vehicle.chassisNumber = this.vehicle.chassisNumber.toUpperCase()
        this.vehicle.chassisNumber = this.vehicle.chassisNumber.replace(/[^A-HJ-NPR-Za-hj-npr-z0-9]/g, '')
      }
      if (this.vehicle.deviceID) {
        this.vehicle.deviceID = this.vehicle.deviceID.replace(/\D/g, '')
      }
    },
  }
}
</script>
<style scoped>
#errorText {
  color: red;
}

#successText {
  color: green;
}</style>