<template>
  <div class="header_section">
    <div class="page-loader" v-if="!isLoaded">
      <div>
        <img src="/images/Rhombus.gif">
        <h1>{{ $t('header.loading') }}</h1>
      </div>
    </div>
    <div class="header_top">
      <div class="container-fluid header_top_container">
        <div class="lang_box dropdown">
          <a title="Language" class="nav-link" @click="changeLanguage('en')" data-toggle="dropdown" aria-expanded="true">
            <img :src="selectedFlag" alt="flag" class="" title="United Kingdom">
            <i class="fa fa-angle-down" aria-hidden="true"></i>
          </a>
          <div class="dropdown-menu">
            <a class="dropdown-item" @click="changeLanguage('tr')">
              <img src="images/flag/flag-tr.png" class="" alt="flag">
            </a>
          </div>
        </div>
        <div class="contact_nav">
          <a href="">
            <i class="fa fa-phone" aria-hidden="true"></i>
            <span>
              {{ $t('header.contact.call') }}
            </span>
          </a>
          <a href="">
            <i class="fa fa-envelope" aria-hidden="true"></i>
            <span>
              E-Mail:info@ozkantech.net
            </span>
          </a>
          <a href="">
            <i class="fa fa-map-marker" aria-hidden="true"></i>
            <span>
              {{ $t('header.contact.location') }}
            </span>
          </a>
        </div>
        <div class="social_box">
          <a href="">
            <i class="fa fa-facebook" aria-hidden="true"></i>
          </a>
          <a href="">
            <i class="fa fa-twitter" aria-hidden="true"></i>
          </a>
          <a href="">
            <i class="fa fa-linkedin" aria-hidden="true"></i>
          </a>
          <a href="">
            <i class="fa fa-instagram" aria-hidden="true"></i>
          </a>
        </div>
      </div>
    </div>
    <div class="header_bottom">
      <div class="container-fluid">
        <nav class="navbar navbar-expand-lg custom_nav-container">
          <a class="navbar-brand" href="/">
            <img src="images/logo3.png" alt="">
          </a>
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class=""> </span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav">
              <li class="nav-item active">
                <a @click="goToSection('home')" class="nav-link">{{ $t('navbar.home') }} <span
                    class="sr-only">(current)</span></a>
              </li>
              <li class="nav-item">
                <a @click="goToSection('services')" class="nav-link">{{ $t('navbar.features') }}</a>
              </li>
              <li class="nav-item">
                <a @click="goToSection('why-us')" class="nav-link">{{ $t('navbar.how-it-works') }}</a>
              </li>
              <li class="nav-item">
                <a @click="goToSection('about')" class="nav-link">{{ $t('navbar.benefist') }}</a>
              </li>
              <li class="nav-item">
                <a @click="goToSection('contact-us')" class="nav-link">{{ $t('navbar.contact') }}</a>
              </li>
            </ul>
            <button id="login">
              <a @click="goToLoginPage">{{ $t('navbar.login') }}</a>
            </button>
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>
<script>
let currentIndex = 0
export default {
  data() {
    return {
      selectedFlag: this.lang(),
      isLoaded: false
    }
  },
  mounted() {    
    if (document.readyState === 'complete') {
      this.isLoaded = true
    }
    this.$nextTick(() => {
      this.scrollView()
    })
    document.onreadystatechange = () => {
      if (document.readyState === 'complete') {
        this.isLoaded = true
      }
    }
  },
  methods: {
    goToLoginPage() {
      this.$router.push({ name: 'login' })
    },
    goToSection(section) {
      const lists = document.querySelectorAll('nav ul li')
      lists.forEach((list, index) => {
        list.addEventListener('click', (e) => {
          [...lists].map(list => list.classList.remove('active'))
          list.classList.add('active')
          currentIndex = index
          document.getElementById(section).scrollIntoView({
            behavior: 'smooth'
          })
        })
      })
    },
    changeLanguage(lang) {
      this.$i18n.locale = lang
      if (lang === 'tr') {
        this.selectedFlag = 'images/flag/flag-tr.png'
      } else if (lang === 'en') {
        this.selectedFlag = 'images/flag/flag-en.png'
      }
    },
    scrollView() {
      const sectionNav = document.querySelectorAll('.nav-item')
      const sectionList = ['home', 'services', 'why-us', 'about', 'contact-us']
      const addActiveClass = () => {
        sectionNav.forEach((section, index) => {
          if (index === currentIndex) {
            if (window.location.hash === '#/') {
              document.getElementById(sectionList[currentIndex]).scrollIntoView({ behavior: 'smooth' })
              section.classList.add('active')
            }
          } else {
            section.classList.remove('active')
          }
        })
      }
      window.addEventListener('wheel', (e) => {
        if (e.deltaY > 0) {
          if (currentIndex < sectionNav.length - 1) {
            currentIndex++
            addActiveClass()
            e.preventDefault()
          }
        } else if (e.deltaY < 0 && currentIndex > 0) {
          currentIndex--
          addActiveClass()
          e.preventDefault()
        }
      }, { passive: false })
    },
    lang() {
      return 'images/flag/flag-' + this.$i18n.locale + '.png'
    }
  },
  computed: {
    currentLang() {
      return this.$i18n.locale
    },
    supportedLanguages() {
      return Object.keys(this.$i18n.messages)
    }
  }
}
</script>
<style scoped>
.page-loader {
  z-index: 9999;
  background-color: #ffffff;
  height: 100vh;
  width: 100%;
  display: grid;
  place-items: center;
}
.page-loader div {
  display: grid;
  place-items: center;
}
.page-loader p {
  color: black;
  text-align: center;
}
</style>
