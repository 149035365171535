<template>
  <section class="slider_section ">
    <div id="customCarousel1" class="carousel slide" data-ride="carousel">
      <div class="carousel-inner">
        <div class="carousel-item active">
          <div class="container ">
            <div class="row">
              <div class="col-md-10 mx-auto">
                <div class="detail-box">
                  <h1>{{ $t('home.heroText1') }}</h1>
                  <div class="btn-box">
                    <a @click="goToSection('contact-us')" class="btn1">
                      {{ $t('home.slider.btn1') }}
                    </a>
                    <a @click="goToSection('about')" class="btn2">
                      {{ $t('home.slider.btn2') }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  methods: {
    goToSection(section) {
      const lists = document.querySelectorAll('nav ul li')
      lists.forEach((list, index) => {
        [...lists].map(list => list.classList.remove('active'))
      })
      if (section === 'contact-us') {
        lists[4].classList.add('active')
      }
      if (section === 'about') {
        lists[3].classList.add('active')
      }
      document.getElementById(section).scrollIntoView({
        behavior: 'smooth'
      })
    }
  }
}
</script>