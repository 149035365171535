<template>
  <div class="home">
    <section id="home" class="hero_area home-div">
      <!-- slider section -->
      <mainHeader />
      <sectionSlider />
      <!-- end slider section -->
    </section>
    <section id="services" class="services home-div">
      <div class="container">
        <div>
          <h2>{{ $t('home.services.heading') }}</h2>
          <p>{{ $t('home.services.p1') }}</p>
          <p>{{ $t('home.services.p2') }}</p>
          <p>{{ $t('home.services.p3') }}</p>
          <p>{{ $t('home.services.p4') }}</p>
          <p>{{ $t('home.services.p5') }}</p>
          <p>{{ $t('home.services.p6') }}</p>
          <iframe  src="https://www.youtube.com/embed/UW1eyvnf-gg?si=H0NycSce4pPLN_uk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          
        </div>
      </div>
    </section>
    <!-- why us section -->
    <section id="why-us" class="why_us_section layout_padding home-div why-us">
      <div class="container">
        <div class="row">
          <h1>{{ $t('home.howItWorks.heading') }}</h1>
          <div id="plan" class="hover_content_block col-sm-3">
            <div class="overlay-darker"></div>
            <div class="overlay"></div>
            <div class="Hover_con_text">
              <i class="Hover_con_icon fa fa-download"></i>
              <h2>{{ $t('home.howItWorks.heading1') }}</h2>
              <p>{{ $t('home.howItWorks.p1') }}</p>
            </div>
          </div>
          <div id="develop" class="hover_content_block col-sm-3 col3">
            <div class="overlay-darker"></div>
            <div class="overlay"></div>
            <div class="Hover_con_text">
              <i class="Hover_con_icon fa fa-floppy-o"></i>
              <h2>{{ $t('home.howItWorks.heading2') }}</h2>
              <p>{{ $t('home.howItWorks.p2') }}</p>
            </div>
          </div>
          <div id="launch" class="hover_content_block col-sm-3 col3">
            <div class="overlay-darker"></div>
            <div class="overlay"></div>
            <div class="Hover_con_text">
              <i class="Hover_con_icon fa fa-cloud"></i>
              <h2>{{ $t('home.howItWorks.heading3') }}</h2>
              <p>{{ $t('home.howItWorks.p3') }}</p>
            </div>
          </div>
          <div id="plan" class="hover_content_block col-sm-3 col3">
            <div class="overlay-darker"></div>
            <div class="overlay"></div>
            <div class="Hover_con_text">
              <i class="Hover_con_icon fa fa-desktop"></i>
              <h2>{{ $t('home.howItWorks.heading4') }}</h2>
              <p>{{ $t('home.howItWorks.p4') }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- end why us section -->
    <!-- about section -->
    <section id="about" class="about home-div">
      <div class="container  ">
        <div>
          <h2>{{ $t('home.about.heading') }}</h2>
          <p>{{ $t('home.about.p1') }}</p>
          <p>{{ $t('home.about.p2') }}</p>
          <p>{{ $t('home.about.p3') }}</p>
          <p>{{ $t('home.about.p4') }}</p>
          <p>{{ $t('home.about.p5') }}</p>
        </div>
      </div>
    </section>
    <!-- end about section -->
    <!-- contact section -->
    <section id="contact-us" class="contact-us contact_section layout_padding-bottom home-div">
      <div class="container-fluid">
        <div class="row">
          <div class="contact-form col-lg-4 col-md-5 offset-md-1">
            <div class="form_container">
              <h2>{{ $t('home.contact.heading') }}</h2>
              <form action="">
                <div>
                  <input type="text" :placeholder="$t('home.contact.yourName')" />
                </div>
                <div>
                  <input type="text" :placeholder="$t('home.contact.phoneNumber')" />
                </div>
                <div>
                  <input type="email" :placeholder="$t('home.contact.email')" />
                </div>
                <div>
                  <input type="text" :placeholder="$t('home.contact.message')" />
                </div>
                <div class="btn_box">
                  <button>
                    {{ $t('home.contact.sendBtn') }}
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div class="col-lg-7 col-md-6 px-0">
            <div class="map_container">
              <div class="map">
                <div id="googleMap">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3007.154861707884!2d28.628111076465633!3d41.087462914544766!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14b558ff165563b9%3A0x9b0b4f10d6b30ca!2zS2FyYWFnYcOnLCBIYWTEsW1rw7Z5LcSwc3RhbmJ1bCBDZC4gTm86MTQsIDM0NTAwIELDvHnDvGvDp2VrbWVjZS_EsHN0YW5idWw!5e0!3m2!1str!2str!4v1690452969804!5m2!1str!2str"
                    width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade">
                  </iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Video Modal -->
    <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <iframe id="video" :src="videoSrc" width="100%" height="600" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mainHeader from '@/components/main/mainHeader.vue'
import sectionSlider from '@/components/main/sectionSlider'

export default {
  components: {
    mainHeader,
    sectionSlider
  },
  data() {
    return {
      videoSrc: ''
    }
  },
  mounted() {
    this.adjustDivHeight();
  },
  methods: {
    adjustDivHeight() {
      const windowHeight = window.innerHeight;
      const divElements = document.querySelectorAll('.home-div');
      divElements.forEach(div => {
        div.style.height = windowHeight + 'px';
      });
    },
    setVideoSrc(src) {
      this.videoSrc = `${src}?autoplay=1&modestbranding=1&showinfo=0`;
      $('#myModal').on('hide.bs.modal', () => {
        this.videoSrc = '';
      });
    }
  },
  created() {
    window.addEventListener('resize', this.adjustDivHeight);
  }
}
</script>

<style scoped>
.home {
  width: 100%;
}

.services h2,
.about h2 {
  color: #3673a4;
  font-size: 36px;
  margin-bottom: 25px;
}

.services .container,
.about .container {
  height: 100%;
  font-family: auto;
}

.modal-dialog {
  max-width: 800px;
  margin: 30px auto;
}

.modal-body {
  position: relative;
  padding: 0px;
}

.close {
  position: absolute;
  right: -30px;
  top: 0;
  z-index: 999;
  font-size: 2rem;
  font-weight: normal;
  color: #fff;
  opacity: 1;
}
</style>
